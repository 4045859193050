* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif;
}

::selection {
  background: #2c75bf; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #2c75bf; /* Gecko Browsers */
}
